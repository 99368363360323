import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  MenuItem,
  CircularProgress,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import { handleSpringDataMutationRequest } from "../../utils/apiUtils";
import { toast } from "react-toastify";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import moment from "moment";

const initialState = {
  pmBookingId: "",
  pmGatewayName: "",
  pmChargeId: "",
  pmAmount: "",
  pmCurrencyCode: "",
  pmTimestamp: "",
  pmStatus: "",
  pmPaymentType: "",
  pmModuleSource: "",
  pmCardLast4: "",
  pmPaymentMode: "",
  pmPaymentIssuer: "",
};

const CollectPaymentEntry = ({
  hotelId,
  handleClose,
  getMetaGetPaymentsForHotel,
}) => {
  const [formValues, setFormValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "pmCardLast4" && value > 9999) {
      return;
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formValues.pmBookingId)
      newErrors.pmBookingId = "Booking ID is required";
    if (!formValues.pmGatewayName)
      newErrors.pmGatewayName = "Gateway Name is required";
    if (!formValues.pmChargeId) newErrors.pmChargeId = "Charge ID is required";
    if (!formValues.pmAmount || isNaN(formValues.pmAmount))
      newErrors.pmAmount = "Valid amount is required";
    if (!formValues.pmCurrencyCode)
      newErrors.pmCurrencyCode = "Currency Code is required";
    // if (!formValues.pmTimestamp)
    //   newErrors.pmTimestamp = "Timestamp is required";
    if (!formValues.pmStatus) newErrors.pmStatus = "Status is required";
    if (!formValues.pmPaymentType)
      newErrors.pmPaymentType = "Payment Type is required";
    if (!formValues.pmModuleSource)
      newErrors.pmModuleSource = "Module Source is required";
    if (
      !formValues.pmCardLast4 ||
      formValues.pmCardLast4.length !== 4 ||
      isNaN(formValues.pmCardLast4)
    ) {
      newErrors.pmCardLast4 = "Valid last 4 digits of card are required";
    }
    if (!formValues.pmPaymentMode)
      newErrors.pmPaymentMode = "Payment Mode is required";
    if (!formValues.pmPaymentIssuer)
      newErrors.pmPaymentIssuer = "Payment Issuer is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const payload = { ...formValues };
      payload.pmHotelId = hotelId;
      payload.pmTimestamp = moment(selectedDate.$d).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      handleSpringDataMutationRequest(
        "POST",
        `v1/payments/manual/add-payment-entry?hotelId=${hotelId}`,
        payload
      )
        .then((data) => {
          toast.success(data);
          setFormValues(initialState);
          setLoading(false);
          handleClose();
          getMetaGetPaymentsForHotel();
        })
        .catch((err) => {
          toast.error(err?.response?.data);
          setLoading(false);
        });
    }
  };

  // Function to handle date change
  const handleDateChange = (newValue) => {
    console.log(newValue);
    setSelectedDate(newValue);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Box>
          <IconButton onClick={handleClose}>
            <HighlightOffIcon sx={{ fontSize: 32 }} />
          </IconButton>
        </Box>
        <Box>
          <Typography
            sx={{ fontSize: 28, fontWeight: 400, textAlign: "right" }}
          >
            Manual payment
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mb: 3 }} />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                sx={{ width: "100%" }}
                label="Select time stamp"
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
                ampm={false}
              />
            </LocalizationProvider>
          </Grid>
          {Object.keys(initialState)
            ?.filter((elm) => elm !== "pmTimestamp")
            .map((key) => (
              <Grid item xs={12} sm={6} key={key}>
                <TextField
                  fullWidth
                  label={key.replace("pm", "").replace(/([A-Z])/g, " $1")}
                  name={key}
                  value={formValues[key]}
                  onChange={handleChange}
                  error={!!errors[key]}
                  helperText={errors[key] || ""}
                  type={
                    ["pmAmount", "pmCardLast4"]?.includes(key)
                      ? "number"
                      : "text"
                  }
                  select={[
                    "pmGatewayName",
                    "pmPaymentType",
                    "pmPaymentMode",
                    "pmCurrencyCode",
                    "pmStatus",
                    "pmPaymentIssuer",
                    "pmModuleSource",
                  ]?.includes(key)}
                >
                  {key === "pmGatewayName" &&
                    [
                      { value: "STRIPE", label: "STRIPE" },
                      { value: "FORTIS", label: "FORTIS" },
                      { value: "RAZORPAY", label: "RAZORPAY" },
                      { value: "SHIFT4", label: "SHIFT4" },
                    ]?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  {key === "pmPaymentType" &&
                    [
                      { value: "CREDIT", label: "CREDIT" },
                      { value: "DEBIT", label: "DEBIT" },
                    ]?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  {key === "pmPaymentMode" &&
                    [
                      { value: "CARD", label: "CARD" },
                      { value: "EMV_CARD", label: "EMV_CARD" },
                    ]?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  {key === "pmCurrencyCode" &&
                    [
                      { value: "USD", label: "USD" },
                      { value: "INR", label: "INR" },
                    ]?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  {key === "pmStatus" &&
                    [
                      { value: "SUCCESS", label: "SUCCESS" },
                      { value: "FAILURE", label: "FAILURE" },
                      { value: "AUTH", label: "AUTH" },
                      { value: "VOID", label: "VOID" },
                    ]?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  {key === "pmPaymentIssuer" &&
                    [
                      { value: "VISA", label: "VISA" },
                      { value: "MASTERCARD", label: "MASTERCARD" },
                      { value: "AMEX", label: "AMEX" },
                      { value: "DISCOVER", label: "DISCOVER" },
                      { value: "DEBIT", label: "DEBIT" },
                    ]?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  {key === "pmModuleSource" &&
                    [
                      { value: "BOOKING_FOLIO", label: "BOOKING_FOLIO" },
                      { value: "BE_PAYMENT", label: "BE_PAYMENT" },
                    ]?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            ))}
        </Grid>
        <Box sx={{ mt: 4 }}>
          <Button
            type="submit"
            variant="custom-button"
            disabled={loading}
            sx={{ width: 300 }}
          >
            Add payment entry {loading && <CircularProgress size={24} />}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default CollectPaymentEntry;
