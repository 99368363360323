import React, { useEffect, useState } from "react";

import Table from "../../components/Table";
import { handleDjangoDataRequest } from "../../utils/apiUtils";
import { toRegulerCase } from "../../utils/Utils";
import TableSkeleton from "../../components/TableSkeleton";
import Nodata from "../../components/Nodata";
import { Box, Button, Drawer } from "@mui/material";

import Reconciliation from "../transaction/Reconciliation";
import CollectPaymentEntry from "../transaction/CollectPaymentEntry";
import RefundEntry from "../transaction/RefundEntry";

export default function Payments({ hotelId }) {
  const access = JSON.parse(localStorage.getItem("metaAccessControl"));
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState();

  const [drawerItem, setDrawerItem] = useState("reconciliation");
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (item) => {
    setDrawerItem(item);
    setOpen(true);
  };

  const handleColumn = (data) => {
    const culumnData = Object.keys(data[0]).map((elm) => ({
      field: elm,
      headerName: toRegulerCase(elm),
    }));
    setColumnDefs(culumnData);
  };
  //---------------Skeleton--------
  const [table, setTable] = useState(false);
  const getMetaGetPaymentsForHotel = () => {
    setTable(false);
    handleDjangoDataRequest(
      `api/v2/metaAdmin/metaGetPaymentsForHotel/?hotel_id=${hotelId}`
    )
      .then((data) => {
        setTable(true);
        handleColumn(data);
        setRowData(data);
      })
      .catch((err) => setTable(true));
  };
  useEffect(() => {
    getMetaGetPaymentsForHotel();
  }, []);

  return (
    <>
      <Box sx={{ mb: 2, display: "flex", gap: 3 }}>
        <Button
          variant="custom-button"
          onClick={() => handleOpen("reconciliation")}
        >
          Manual reconciliation
        </Button>
        {access?.ManualTransaction && (
          <Button variant="custom-button" onClick={() => handleOpen("payment")}>
            Add payment entry
          </Button>
        )}
        {access?.ManualTransaction && (
          <Button variant="custom-button" onClick={() => handleOpen("refund")}>
            Add refund entry
          </Button>
        )}
      </Box>
      <Drawer anchor="right" open={open} onClose={handleClose}>
        <Box sx={{ width: 800, p: 2 }}>
          {drawerItem === "reconciliation" && (
            <Reconciliation
              hotelId={hotelId}
              handleClose={handleClose}
              getMetaGetPaymentsForHotel={getMetaGetPaymentsForHotel}
            />
          )}
          {drawerItem === "payment" && (
            <CollectPaymentEntry
              hotelId={hotelId}
              handleClose={handleClose}
              getMetaGetPaymentsForHotel={getMetaGetPaymentsForHotel}
            />
          )}
          {drawerItem === "refund" && (
            <RefundEntry
              hotelId={hotelId}
              handleClose={handleClose}
              getMetaGetPaymentsForHotel={getMetaGetPaymentsForHotel}
            />
          )}
        </Box>
      </Drawer>

      {table ? (
        rowData ? (
          <Table rowData={rowData} columnDefs={columnDefs} />
        ) : (
          <Nodata />
        )
      ) : (
        <TableSkeleton />
      )}
    </>
  );
}
