import React from "react";
import { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Index from "../pages/propertyDashboard/Index";
import AccesControl from "../pages/AccesControl";
import ChannelLogsForHotel from "../pages/distributionManager/ChannelLogsForHotel";
import ChannelManager from "../pages/distributionManager/ChannelManager";
import ChannelMappingForHotel from "../pages/distributionManager/ChannelMappingForHotel";
import Channels from "../pages/distributionManager/Channels";
import { PrivateRoute } from "./PrivateRoute";
import Email from "../pages/Email";
import AccountsDashBoardManagement from "../pages/accounts/acc/AccountsDashBoardManagement";
import Commission from "../pages/Commission";
import RateShoper from "../pages/RateShoper";
import CustomNotif from "../pages/propertyDashboard/CustomNotif";

const UserLogin = lazy(() => import("../pages/UserLogin"));
const HeaderPage = lazy(() => import("../pages/HeaderPage"));
const Propertydashboard = lazy(() => import("../pages/Propertydashboard"));
const Accounts = lazy(() => import("../pages/Accounts"));
const DistributionManager = lazy(() => import("../pages/DistributionManager"));
const Reports = lazy(() => import("../pages/Reports"));
const NoPage = lazy(() => import("../pages/NoPage"));

export default function MetaRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<UserLogin />} />

        <Route
          path="Dashboard"
          element={
            <PrivateRoute>
              <HeaderPage />
            </PrivateRoute>
          }
        >
          <Route index element={<Propertydashboard />} />
          <Route path="Dash" element={<Index />} />
          <Route path="Accounts" element={<Accounts />} />
          {/* <Route path="Accounting" element={<AccountIndex />} /> */}
          <Route path="Accounting" element={<AccountsDashBoardManagement />} />
          <Route path="DistributionManager" element={<DistributionManager />}>
            <Route index element={<Channels />} />
            <Route path="ChannelManager" element={<ChannelManager />} />
            <Route path="ChannelMapping" element={<ChannelMappingForHotel />} />
            <Route path="ChannelLogs" element={<ChannelLogsForHotel />} />
          </Route>
          <Route path="AccesControl" element={<AccesControl />} />
          <Route path="Reports" element={<Reports />} />
          <Route path="Email" element={<Email />} />
          <Route path="Commissions" element={<Commission />} />
          <Route path="Rates" element={<RateShoper />} />
          <Route path="customNotif" element={<CustomNotif />} />
          {/* <Route path="manual-transaction" element={<ManualTransaction />} /> */}
        </Route>
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}
