import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Grid,
  IconButton,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { handleSpringDataRequest } from "../../utils/apiUtils";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function Reconciliation({
  hotelId,
  handleClose,
  getMetaGetPaymentsForHotel,
}) {
  const [BookingId, setBookingId] = useState();
  const [pgPaymentId, setPgPaymentId] = useState();

  const [submitLoader, setSubmitLoader] = useState(false);
  const handlePullButton = () => {
    if (!BookingId || !pgPaymentId) {
      toast.error("All fields are mandatory");
      return;
    }
    setSubmitLoader(true);
    handleSpringDataRequest(
      `core/api/v1/payment-service/reconcile-payment-meta?hotelId=${hotelId}&bookingId=${BookingId}&paymentId=${pgPaymentId}`
    )
      .then((data) => {
        setSubmitLoader(false);
        handleClose();
        toast.success(data);
        getMetaGetPaymentsForHotel();
      })
      .catch((err) => {
        setSubmitLoader(false);
        toast.error(err);
      });
  };
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Box>
          <IconButton onClick={handleClose}>
            <HighlightOffIcon sx={{ fontSize: 32 }} />
          </IconButton>
        </Box>
        <Box>
          <Typography
            sx={{ fontSize: 28, fontWeight: 400, textAlign: "right" }}
          >
            Manual reconciliation
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Booking ID"
            variant="outlined"
            value={BookingId}
            onChange={(e) => setBookingId(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="PG payment ID "
            variant="outlined"
            value={pgPaymentId}
            onChange={(e) => setPgPaymentId(e.target.value)}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 4 }}>
        <Button
          sx={{ width: 300 }}
          variant="custom-button"
          onClick={handlePullButton}
          disabled={submitLoader}
        >
          Pull {submitLoader && <CircularProgress size="0.9rem" />}
        </Button>
      </Box>
    </Box>
  );
}
