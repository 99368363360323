import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  MenuItem,
  CircularProgress,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import { handleSpringDataMutationRequest } from "../../utils/apiUtils";
import { toast } from "react-toastify";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import moment from "moment";

const initialState = {
  bookingId: "",
  refundAmount: "",
  refundStatus: "",
  creationDate: "",
  sfPaymentId: "",
  pgRefundId: "",
};

const RefundEntry = ({ hotelId, handleClose, getMetaGetPaymentsForHotel }) => {
  const [formValues, setFormValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formValues.bookingId) newErrors.bookingId = "Booking ID is required";
    if (!formValues.refundAmount || isNaN(formValues.refundAmount)) {
      newErrors.refundAmount = "Valid refund amount is required";
    }
    if (!formValues.refundStatus)
      newErrors.refundStatus = "Refund Status is required";
    // if (!formValues.creationDate)
    //   newErrors.creationDate = "Creation Date is required";
    if (!formValues.sfPaymentId || isNaN(formValues.sfPaymentId)) {
      newErrors.sfPaymentId = "Valid Payment ID is required";
    }
    if (!formValues.pgRefundId) newErrors.pgRefundId = "Refund ID is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const payload = { ...formValues };
      payload.hotelId = hotelId;
      payload.creationDate = moment(selectedDate.$d).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      handleSpringDataMutationRequest(
        "POST",
        `v1/payments/manual/add-refund-entry?hotelId=${hotelId}`,
        payload
      )
        .then((data) => {
          toast.success(data);
          setFormValues(initialState);
          setLoading(false);
          getMetaGetPaymentsForHotel();
          handleClose();
        })
        .catch((err) => {
          toast.error(err?.response?.data);
          setLoading(false);
        });
    }
  };

  // Function to handle date change
  const handleDateChange = (newValue) => {
    console.log(newValue);
    setSelectedDate(newValue);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Box>
          <IconButton onClick={handleClose}>
            <HighlightOffIcon sx={{ fontSize: 32 }} />
          </IconButton>
        </Box>
        <Box>
          <Typography
            sx={{ fontSize: 28, fontWeight: 400, textAlign: "right" }}
          >
            Manual refund
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mb: 3 }} />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                sx={{ width: "100%" }}
                label="Select time stamp"
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
                ampm={false}
              />
            </LocalizationProvider>
          </Grid>
          {Object.keys(initialState)
            ?.filter((item) => item !== "creationDate")
            .map((key) => (
              <Grid item xs={12} sm={6} key={key}>
                <TextField
                  fullWidth
                  label={key.replace(/([A-Z])/g, " $1")}
                  name={key}
                  value={formValues[key]}
                  onChange={handleChange}
                  error={!!errors[key]}
                  helperText={errors[key] || ""}
                  select={key === "refundStatus"}
                  type={
                    ["refundAmount", "sfPaymentId"]?.includes(key)
                      ? "number"
                      : "text"
                  }
                >
                  {key === "refundStatus" &&
                    [
                      { value: "SUCCESS", label: "SUCCESS" },
                      { value: "FAILURE", label: "FAILURE" },
                      // { value: "AUTH", label: "AUTH" },
                      // { value: "VOID", label: "VOID" },
                    ].map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            ))}
        </Grid>
        <Box sx={{ mt: 4 }}>
          <Button
            type="submit"
            variant="custom-button"
            disabled={loading}
            sx={{ width: 300 }}
          >
            Add refund entry {loading && <CircularProgress size={24} />}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default RefundEntry;
